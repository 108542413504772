<template>
  <section>
    <!-- <v-btn @click="runAllGrades()">RUN ALL GRADES</v-btn> -->
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="red"
    ></v-progress-linear>

    <v-simple-table v-if="schools" class="bborder">
      <thead>
        <tr>
          <th class="pl-2">No</th>
          <th class="pl-2">Name</th>
          <th
            class="text-center"
            v-for="(dep, dindex) in deps"
            :key="'body-' + dindex"
          >
            <div>
              <div @click="allDepartment(dep.name)" style="cursor: pointer">
                {{ dep.name }}
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(school, sindex) in schools" :key="'school-' + sindex">
          <td class="pl-2">{{ sindex + 1 }}</td>
          <td class="pl-2" style="width: 300px" @click="saveSchool(school)">
            {{ school.name }}
          </td>
          <td
            :data-title2="school.name + '-' + gradeIndex.name"
            class="text-center pa-0"
            v-for="gradeIndex in deps"
            :key="'body2-' + gradeIndex.name"
            :style="
              school.departments &&
              school.departments[gradeIndex.name - 1].exists
                ? school.departments[gradeIndex.name - 1][
                    'savedGradesToEsis-3'
                  ] &&
                  school.departments[gradeIndex.name - 1][
                    'savedNULLGradesToEsis'
                  ]
                  ? getDataAsString(
                      school.departments[gradeIndex.name - 1][
                        'savedNULLGradesToEsis'
                      ]
                    ) == '100%'
                    ? 'background-color: #ccffc7;'
                    : getDataAsString(
                        school.departments[gradeIndex.name - 1][
                          'savedNULLGradesToEsis'
                        ]
                      ) == '0%'
                    ? 'background-color: white; cursor:pointer'
                    : 'background-color: #faff9f;'
                  : 'background-color: white; cursor:pointer'
                : 'background-color: #ffb6b6'
            "
            @click="
              saveDepartmentScore(
                school,
                school.departments[gradeIndex.name - 1]
              )
            "
          >
            <v-progress-linear
              v-if="
                school.departments &&
                school.departments[gradeIndex.name - 1] &&
                school.departments[gradeIndex.name - 1].loading
              "
              indeterminate
              color="red"
            ></v-progress-linear>
            <small v-else>
              {{
                school.departments &&
                school.departments[gradeIndex.name - 1].exists
                  ? school.departments[gradeIndex.name - 1][
                      "savedGradesToEsis-3"
                    ]
                    ? getDataAsString(
                        school.departments[gradeIndex.name - 1][
                          "savedGradesToEsis-3"
                        ]
                      )
                    : "-"
                  : "x"
              }},
              {{
                school.departments &&
                school.departments[gradeIndex.name - 1].exists
                  ? school.departments[gradeIndex.name - 1][
                      "savedNULLGradesToEsis"
                    ]
                    ? getDataAsString(
                        school.departments[gradeIndex.name - 1][
                          "savedNULLGradesToEsis"
                        ]
                      )
                    : "-"
                  : ""
              }}
            </small>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-dialog
      v-model="showProgramLessonsDialog"
      v-if="programLessons"
      width="1000"
    >
      <v-card class="pa-3">
        <div
          @click="print22(lesson)"
          v-for="(lesson, lindex) in programLessons"
          :key="'lesson2' + lindex"
        >
          {{ lindex + 1 }}. {{ lesson.courseInfo.COURSE_NAME }} -
          {{ lesson.program.fullName }} -
          <span
            v-html="
              lesson.halfYearId == 3
                ? lesson.finalGradeSavedToEsis
                  ? `<span class='green--text'>Saved</span>`
                  : `<span class='red--text'>Not Saved</span>`
                : lesson['unelgee-' + lesson.halfYearId + '-SavedToEsis']
                ? `<span class='green--text'>Saved</span>`
                : `<span class='red--text'>Not Saved</span>`
            "
          ></span>
        </div>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
const fb = require("@/firebaseConfig.js");
export default {
  data: () => ({
    schools: null,
    loading: false,
    deps: [
      { name: 12 },
      { name: 11 },
      { name: 10 },
      { name: 9 },
      { name: 8 },
      { name: 7 },
      { name: 6 },
      { name: 5 },
      { name: 4 },
      { name: 3 },
      { name: 2 },
      { name: 1 },
    ],
    headerNames: [
      {
        text: "ID",
        align: "start",
        width: "1%",
        value: "index",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Бүлэг",
        value: "STUDENT_GROUP_NAME",
      },
    ],
    programLessons: null,
    showProgramLessonsDialog: false,
  }),
  computed: {
    ...mapState(["userProfile", "currentUser"]),
  },
  created() {
    fb.db
      .collection("schools")
      .orderBy("name", "asc")
      .get()
      .then((docs) => {
        this.schools = [];
        docs.forEach(async (doc) => {
          let school = doc.data();
          school.ref = doc.ref;
          school.id = doc.id;
          school.departments = null;
          if (school._esis_schoolInfo) {
            await school.ref
              .collection("departments-" + school.currentYear)
              .where("deleted", "==", false)
              .orderBy("index", "desc")
              .onSnapshot((docs) => {
                school.departments = [
                  { index: 1 },
                  { index: 2 },
                  { index: 3 },
                  { index: 4 },
                  { index: 5 },
                  { index: 6 },
                  { index: 7 },
                  { index: 8 },
                  { index: 9 },
                  { index: 10 },
                  { index: 11 },
                  { index: 12 },
                ];
                docs.forEach(async (doc) => {
                  let department = doc.data();
                  department.ref = doc.ref;
                  department.id = doc.id;
                  department.exists = true;
                  await department.ref
                    .collection("programs")
                    .where("deleted", "==", false)
                    .onSnapshot((docs) => {
                      department["savedGradesToEsis-3"] = null;
                      department.programs = [];
                      docs.forEach((doc) => {
                        let prog = doc.data();
                        prog.ref = doc.ref;
                        prog.id = doc.id;

                        if (prog["savedGradesToEsis-3"]) {
                          if (!department["savedGradesToEsis-3"])
                            department["savedGradesToEsis-3"] =
                              prog["savedGradesToEsis-3"];
                          else {
                            department[
                              "savedGradesToEsis-3"
                            ].totalLessonCounter +=
                              prog["savedGradesToEsis-3"].totalLessonCounter;
                            department[
                              "savedGradesToEsis-3"
                            ].successfulLessonCounter +=
                              prog[
                                "savedGradesToEsis-3"
                              ].successfulLessonCounter;
                          }
                        }

                        if (prog["savedNULLGradesToEsis"]) {
                          if (!department["savedNULLGradesToEsis"])
                            department["savedNULLGradesToEsis"] =
                              prog["savedNULLGradesToEsis"];
                          else {
                            department[
                              "savedNULLGradesToEsis"
                            ].totalLessonCounter +=
                              prog["savedNULLGradesToEsis"].totalLessonCounter;
                            department[
                              "savedNULLGradesToEsis"
                            ].successfulLessonCounter +=
                              prog[
                                "savedNULLGradesToEsis"
                              ].successfulLessonCounter;
                          }
                        }

                        department.programs.push(prog);
                        this.$forceUpdate();
                      });
                    });
                  var found = school.departments.find(
                    (dep) => dep.index == department.index
                  );
                  if (found) {
                    school.departments[school.departments.indexOf(found)] =
                      department;
                  }
                  this.$forceUpdate();
                });
              });
            this.schools.push(school);
          }
        });
      });
  },
  methods: {
    print22(lesson) {
      console.log(lesson.ref.path, lesson);
    },
    async runAllGrades() {
      var counter = 0;
      for (var gradeIndex of this.deps) {
        this.allDepartment(gradeIndex.name);
        counter++;
        if (counter == 3) {
          console.log("waiting 9 min");
          await new Promise((resolve) => setTimeout(resolve, 540000));
        }
      }
      console.log("DONEEEE");
    },
    allDepartment(depName) {
      for (let school of this.schools) {
        this.saveDepartmentScore(school, school.departments[depName - 1]);
      }
    },
    saveSchool(school) {
      for (var gradeIndex of this.deps) {
        this.saveDepartmentScore(
          school,
          school.departments[gradeIndex.name - 1]
        );
      }
    },
    getDataAsString(data) {
      if (data) {
        if (data.totalLessonCounter == 0) {
          return "100%";
        }
        return (
          (
            (data.successfulLessonCounter / data.totalLessonCounter) *
            100
          ).toFixed(0) + "%"
        );
      }
      return 0;

      // return data.successfulLessonCounter + "/" + data.totalLessonCounter;
    },
    showError() {
      fb.db
        .collection("schoolGradeErrorLogs")
        .where("failReason", "==", "missing plans")
        .get()
        .then((docs) => {
          console.log(docs.size);
        });
    },
    async testingg() {
      const headers = {
        "Content-Type": "application/json",
      };
      var requestBody = {
        GRADING_SCHEME_ID: 14,
        school: this.schools[10],
        token: false,
      };
      await axios
        .post(
          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/gradeIdRetriever",
          requestBody,
          headers
        )
        .then((response) => {
          console.log(response);
        });
    },
    isThisSemesterLesson(lesson, selectedHalfYearId) {
      if (lesson.startEnd) {
        if (selectedHalfYearId > 1) {
          if (
            (lesson.startEnd["semester-3"] &&
              lesson.startEnd["semester-3"].available) ||
            (lesson.startEnd["semester-4"] &&
              lesson.startEnd["semester-4"].available)
          ) {
            lesson.isNotThisSemester = false;
          } else {
            lesson.isNotThisSemester = true;
          }
        } else {
          if (
            (lesson.startEnd["semester-1"] &&
              lesson.startEnd["semester-1"].available) ||
            (lesson.startEnd["semester-2"] &&
              lesson.startEnd["semester-2"].available)
          ) {
            lesson.isNotThisSemester = false;
          } else {
            lesson.isNotThisSemester = true;
          }
        }
      } else {
        lesson.isNotThisSemester = false;
      }
    },
    async saveDepartmentScore(school, department) {
      console.log(department.index);
      if (
        department.exists &&
        this.getDataAsString(
          school.departments[department.index - 1]["savedGradesToEsis-3"]
        ) != 0
      ) {
        department.loading = true;
        this.$forceUpdate();

        var halfYear2Date = new Date(
          new Date().getFullYear() +
            "-" +
            this.$store.state.calendarButez2[3].months[
              this.$store.state.calendarButez2[3].months.length - 1
            ].name +
            "-" +
            this.$store.state.calendarButez2[3].months[
              this.$store.state.calendarButez2[3].months.length - 1
            ].days[
              this.$store.state.calendarButez2[3].months[
                this.$store.state.calendarButez2[3].months.length - 1
              ].days.length - 1
            ].day
        );
        const headers = {
          "Content-Type": "application/json",
        };

        for (var program of department.programs) {
          var requestBody = {
            schoolRef: school.ref.path,
            programRef: program.ref.path,
            halfYearDate: halfYear2Date,
          };

          var response = await axios
            .post(
              "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/programNULLGradesToEsis",
              requestBody,
              headers
            )
            .then((response) => {
              return response.data;
            });
          console.log(response);
          console.log(
            response.failedLessons.length > 0 ? response.failedLessons : "good"
          );
        }

        department.loading = false;
        this.$forceUpdate();
      }
    },
  },
};
</script>
<style>
.bborder {
  border-left: 1px solid #bbb;
  border-bottom: 1px solid #bbb;
}
.bborder td {
  border-right: 1px solid #bbb !important;
  padding: 0 !important;
}
.bborder th {
  border: 1px solid #bbb;
  border-left: 0px;
  padding: 0 !important;
}
[data-title2]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title2]:after {
  content: attr(data-title2);
  position: absolute;
  bottom: -1.6em;
  right: 65%;
  padding: 4px 4px 4px 8px;
  color: #222;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background: rgb(0, 60, 255);
  color: white;
  opacity: 0;
  z-index: 2;
  visibility: hidden;
}

[data-title2] {
  position: relative;
}
</style>
